.nav-item,
.nav-item:visited {
    text-decoration: none;
    border: none;
    color: #ffffff;
}
.menu {
    position: relative;
    top: 65px;
}
.nav-menu-header {
    color: #ffffff;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
    transition: 0.3s;
    border-bottom: 1px solid #ddd;
}
.nav-menu-header div {
    padding: 10px 0;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}
.nav-menu-header div span {
    position: relative;
    right: -20px;
    font-size: 36px;
}
.nav-menu-arrow-container {
    position: relative;
    top: -8px;
    right: 25px;
    float: right;
}
.white {
    color: #ffffff;
}
.active {
    border: none;
    pointer-events: auto;
}
.inactive {
    color: #aaa !important;
    pointer-events: none;
}
.I-100 {
    background-color: #ff7f00;
}
.I-300 {
    background-color: #370707;
}
.I-350 {
    background-color: #313131;
}
.I-600 {
    background-color: #382f16;
}
.I-900 {
    background-color: #000000;
}
.sub-menu-container {
    margin: 0 0 15px 0;
    padding: 0;
    transition: all 350ms ease-in-out;
}
.sub-menu-container div {
    margin: 2px auto;
    line-height: 1;
}
