nav {
    position: fixed;
    height: 62px;
    width: 95%;
    z-index: 2019;
}

header {
    position: relative;
    top: 64px;
    z-index: 2018;
}

form {
    position: relative;
    z-index: 2018;
    top: 62px;
}

.row .col-xs-6 {
    margin-top: auto;
    padding-bottom: 10px;
}
.row .col-xs-6 div {
    margin-bottom: auto;
}

.input-filter {
    margin-bottom: 5px;
    border-radius: 0px;
}

ol {
    margin: 0;
    position: relative;
    left: -1.5em;
}

ol li {
    margin-bottom: 0.5em;
}

.no-bullet {
    list-style-type: none;
    position: relative;
    left: -2em;
}

.no-bullet li {
    margin-bottom: 1em;
}

.hide {
    display: none;
}

.show {
    display: inline-block;
}

label.sublabel {
    font-weight: normal;
    font-size: 16px;
}

input.full {
    width: 100%;
}

.extra-padding {
    padding: 15px 0 10px;
}
